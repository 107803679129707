import { configureStore } from "@reduxjs/toolkit";
import login_user_slice from "./redux/auth_slice/login_user_slice";
import sportsComplex_slice from "./redux/zsports_slice/zsports_slice";
import bookingHistory_slice from "./redux/zsports_slice/booking_history_slice";
import sportsComplexes_slice from "./redux/zsports_slice/adminsports_slice";


const initialState = {};

const store = configureStore({
  reducer: {
    loginUser: login_user_slice,
    sportsComplex: sportsComplex_slice,
    bookingHistory: bookingHistory_slice,
    sportsComplexes: sportsComplexes_slice,
    
  },
  preloadedState: initialState,
});

export default store;
