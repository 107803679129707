import React, { useState, useEffect, useRef } from "react";
import { Switch, Route, useLocation, Redirect } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppTopbar } from "./app/features/components/app_top_bar";
import { AppMenu } from "./app/features/components/app_menu";
import { AppConfig } from "./AppConfig";
import { ToastContainer } from "react-toastify";
import LoginScreen from "./app/features/screens/auth/loginscreen";
import EditAddForm from "./app/features/screens/zsports/schedule/edit_add_form";
import ViewDetails from "./app/features/screens/zsports/schedule/view_details";
import ScheduleScreen from "./app/features/screens/zsports/schedule/schedule";
import BookingHistoryScreen from "./app/features/screens/booking-history/booking-history";
import ScheduleCheckerScreen from "./app/features/screens/zsports/schedule-checker/schedule-checker";
import { setUser } from "./app/redux/auth_slice/login_user_slice";
import "react-toastify/dist/ReactToastify.css";
import PrimeReact from "primereact/api";
import { Tooltip } from "primereact/tooltip";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "prismjs/themes/prism-coy.css";
import "./assets/demo/flags/flags.css";
import "./assets/demo/Demos.scss";
import "./assets/layout/layout.scss";
import "./styles/app.scss";
import "./styles/form.scss";
import "./styles/login.scss";


const App = () => {
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [dialogVisible, setDialogVisible] = useState(false);

  const copyTooltipRef = useRef();
  const location = useLocation();
  const history = useHistory();

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  // Store current path in local storage whenever it changes
  useEffect(() => {
    localStorage.setItem("currentPath", location.pathname);
  }, [location.pathname]);

  // Check for stored path on mount and redirect if exists
  useEffect(() => {
    const storedPath = localStorage.getItem("currentPath");
    if (storedPath) {
      history.push(storedPath);
    }
  }, [history]);

  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  const onRipple = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onLayoutModeChange = (mode) => {
    setLayoutMode(mode);
  };

  const onColorModeChange = (mode) => {
    setLayoutColorMode(mode);
  };

  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    event.preventDefault();
  };

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const findCurrentRouteLabel = (location) => {
    const path = location.pathname;

    for (const group of menu) {
      if (group.to === path) {
        return group.label;
      }

      if (group.items) {
        for (const item of group.items) {
          if (path.startsWith(item.to)) {
            return item.label || group.label;
          }
        }
      }
    }

    return "";
  };

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else
      element.className = element.className.replace(
        new RegExp(
          "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive":
      staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active":
      overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });

  const loginUser = useSelector((state) => state.loginUser);
  const { user } = loginUser;
  const dispatch = useDispatch();

  useEffect(() => {
    const userData = localStorage.getItem("user");
    if (userData) {
      dispatch(setUser(JSON.parse(userData)));
    }
  }, [dispatch]);

  useEffect(() => {
    if (!user && !localStorage.getItem("user")) {
      history.push("/login");
    }
  }, [user, history]);

  const menu = [
    {
      label: "Dashboard",
      icon: require("./assets/sports_assests/dashboard.png"),
    },
    {
      label: "Z Sports",
      icon: require("./assets/sports_assests/sports.png"),
      items: [
        { label: "Profile Management" },
        { label: "Schedule Management", to: "/schedule" },
        { label: "Order Management" },
        { label: "Insight" },
      ],
    },
    {
      label: "Booking History",
      icon: require("./assets/sports_assests/history.png"),
      to: "/booking-history",
    },
  ];

  const adminSubMenu = [
    { label: "Profile Management" },
    { label: "Schedule Management", to: "/schedule-checker" },
    { label: "Order Management" },
    { label: "Insight" },
  ];

  const filteredMenu = menu
    .map((item) => {
      if (item.label === "Z Sports" && user?.user?.role === "Admin") {
        return {
          ...item,
          items: adminSubMenu,
        };
      } else if (
        item.label === "Booking History" &&
        user?.user?.role === "Admin"
      ) {
        return null; // remove this item if the user role is Admin
      }
      return item;
    })
    .filter(Boolean);

  return (
    <>
      <ToastContainer />
      <Switch>
        <Route exact path="/login" component={LoginScreen} />
        <Route
          path="/"
          render={() =>
            !localStorage.getItem("user") ? (
              <Redirect to="/login" />
            ) : (
              <>
                {user?.user?.role === "Admin"
                  ? history.location.pathname === "/" && (
                      <Redirect to="/schedule-checker" />
                    )
                  : history.location.pathname === "/" && (
                      <Redirect to="/schedule" />
                    )}
                <div className={wrapperClass} onClick={onWrapperClick}>
                  <Tooltip
                    ref={copyTooltipRef}
                    target=".block-action-copy"
                    position="bottom"
                    content="Copied to clipboard"
                    event="focus"
                  />
                  <div className="layout-sidebar" onClick={onSidebarClick}>
                    <AppMenu
                      model={filteredMenu}
                      layoutColorMode={layoutColorMode}
                    />
                  </div>

                  <div className="layout-main-container">
                    <AppTopbar
                      onToggleMenuClick={onToggleMenuClick}
                      layoutColorMode={layoutColorMode}
                      mobileTopbarMenuActive={mobileTopbarMenuActive}
                      onMobileTopbarMenuClick={onMobileTopbarMenuClick}
                      onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick}
                      currentRouteLabel={findCurrentRouteLabel(location)}
                      user={user}
                    />
                    <div className="layout-main">
                      <Switch>
                        <Route
                          exact
                          path="/schedule"
                          component={ScheduleScreen}
                        />
                        <Route
                          path="/schedule/edit-add-form"
                          component={EditAddForm}
                        />
                        <Route
                          path="/schedule/view-details/:id"
                          component={ViewDetails}
                        />
                        <Route
                          path="/booking-history"
                          component={BookingHistoryScreen}
                        />
                        <Route
                          path="/schedule-checker"
                          component={ScheduleCheckerScreen}
                        />
                      </Switch>
                    </div>
                  </div>
                </div>

                <AppConfig
                  rippleEffect={ripple}
                  onRippleEffect={onRipple}
                  inputStyle={inputStyle}
                  onInputStyleChange={onInputStyleChange}
                  layoutMode={layoutMode}
                  onLayoutModeChange={onLayoutModeChange}
                  layoutColorMode={layoutColorMode}
                  onColorModeChange={onColorModeChange}
                />

                <CSSTransition
                  classNames="layout-mask"
                  timeout={{ enter: 200, exit: 200 }}
                  in={mobileMenuActive}
                  unmountOnExit
                >
                  <div className="layout-mask p-component-overlay"></div>
                </CSSTransition>
              </>
            )
          }
        />
      </Switch>
    </>
  );
};

export default App;
