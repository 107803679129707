import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import config from "../../../config";

// Initial state
const initialState = {
  bookingHistoryList: [],
  loading: false,
  error: null,
};

// Slice definition
const bookingHistoryReducer = createSlice({
  name: "bookingHistory",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookingHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBookingHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingHistoryList = action.payload;
      })
      .addCase(getBookingHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Export reducer and actions
export default bookingHistoryReducer.reducer;
export const { resetChangeStatus } = bookingHistoryReducer.actions;

// Thunk to fetch booking history
export const getBookingHistory = createAsyncThunk(
  "bookingHistory/fetch",
  async ({ mobileNumber, startDate, endDate }, { rejectWithValue }) => {
    try {
      const requestData = {
        mobileNumber,
        startDate,
        endDate,
      };

      const response = await Axios.post(
        `${config.baseUrl}${config.tellerAppPath}/GetBookingHistory`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
