import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
  getSportsComplexes,
  approveSportsComplex,
  rejectSportsComplex,
} from "../../../../redux/zsports_slice/adminsports_slice";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Tag } from "primereact/tag";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import Loader from "../../../components/loader";
import { toast } from "react-toastify";
import { InputTextarea } from "primereact/inputtextarea";
import Paginator from "../../../components/paginator";
import useDebounce from "../../../components/hooks/useDebounce";
import CustomImagePreview from "../../../components/custom_imagepreview";

export default function ScheduleCheckerScreen() {
  const dispatch = useDispatch();

  const sportComplexesReducer = useSelector((state) => state.sportsComplexes);

  const { sportsComplexesList, loading, totalRecords, pageSize, pageNo } =
    sportComplexesReducer;

  const { user } = useSelector((state) => state.loginUser);

  const [filteredList, setFilteredList] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [expandedRows, setExpandedRows] = useState(null);
  const [expandedNestedRows, setExpandedNestedRows] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [isApproveAction, setIsApproveAction] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [currentTab, setCurrentTab] = useState("Pending");
  const [remarksError, setRemarksError] = useState("");
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);

  const debouncedGlobalFilter = useDebounce(globalFilter, 500);

  useEffect(() => {
    fetchSportsComplexes(currentTab, page, rows, debouncedGlobalFilter);
  }, [currentTab, page, rows, debouncedGlobalFilter]);

  useEffect(() => {
    if (sportsComplexesList) {
      setFilteredList(sportsComplexesList);
    }
  }, [sportsComplexesList]);

  const fetchSportsComplexes = (
    tab,
    pageNo = page,
    pageSize = rows,
    searchText = debouncedGlobalFilter
  ) => {
    let isApproved;
    if (tab === "Pending") {
      isApproved = null;
    } else if (tab === "Approved") {
      isApproved = true;
    } else if (tab === "Rejected") {
      isApproved = false;
    }

    dispatch(
      getSportsComplexes({
        pageNo,
        pageSize,
        searchText,
        isApproved,
        token: user?.token,
      })
    );
  };

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  useEffect(() => {
    if (sportsComplexesList) {
      setFilteredList(sportsComplexesList);
    }
  }, [sportsComplexesList]);

  const handleApproveClick = (rowData) => {
    setCurrentRowData(rowData);
    setIsApproveAction(true);
    setShowConfirmDialog(true);
  };

  const handleRejectClick = (rowData) => {
    setCurrentRowData(rowData);
    setIsApproveAction(false);
    setShowConfirmDialog(true);
  };

  const handleApproveConfirm = () => {
    if (!currentRowData) return;

    dispatch(
      approveSportsComplex({
        id: currentRowData.sportsComplexId,
        token: user?.token,
      })
    )
      .then((response) => {
        toast.success("Item approved successfully!");
        setShowConfirmDialog(false);
        fetchSportsComplexes(currentTab);
      })
      .catch((error) => {
        toast.error("Approval failed");
      });
  };

  const handleRejectConfirm = () => {
    if (!currentRowData) return;

    dispatch(
      rejectSportsComplex({
        id: currentRowData.sportsComplexId,
        remarks,
        token: user?.token,
      })
    )
      .then((response) => {
        toast.success("Item rejected successfully!");
        setShowConfirmDialog(false);
        setRemarks("");
        fetchSportsComplexes(currentTab);
      })
      .catch((error) => {
        toast.error("Rejection failed");
      });
  };

  const actionTemplate = (rowData) => {
    if (currentTab === "Pending") {
      return (
        <div className="flex">
          <Button
            className="p-button-rounded p-button-success p-0 mr-5 action-button"
            onClick={() => handleApproveClick(rowData)}
            aria-label="Approve"
            icon="pi pi-check"
            label="Approve"
          />
          <Button
            className="p-button-rounded p-button-danger p-0 mr-5 action-button"
            onClick={() => handleRejectClick(rowData)}
            aria-label="Reject"
            icon="pi pi-times"
            label="Reject"
          />
        </div>
      );
    }
    return null;
  };

  const imageTemplate = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={rowData.image} />
      </div>
    );
  };

  const imageNestedTemplate = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={rowData.sportImage} />
      </div>
    );
  };

  const imageNestedNestedTemplete = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={rowData.courtImage} />
      </div>
    );
  };

  const serialNumberTemplate = (rowData, column) => {
    return column.rowIndex + 1 + first;
  };

  const columnsData = [
    {
      header: "Sr. No",
      body: serialNumberTemplate,
    },

    {
      field: "complexName",
      header: "Complex Name",
    },
    {
      field: "cityName",
      header: "City",
    },

    {
      field: "remarks",
      header: currentTab === "Rejected" ? "Remarks" : null,
      body: (rowData) => {
        if (currentTab === "Rejected" && rowData.isApproved === false) {
          return rowData.remarks || "";
        } else {
          return "";
        }
      },
    },

    {
      header: "Status",
      body: (rowData) => {
        if (rowData.isApproved === true) {
          return <Tag severity="success" value="Approved" />;
        } else if (rowData.isApproved === false) {
          return <Tag severity="danger" value="Rejected" />;
        } else {
          return <Tag severity="contrast" value="Pending" />;
        }
      },
    },
    {
      header: "Image",
      body: imageTemplate,
    },
    {
      header: currentTab === "Pending" ? "Action" : null,
      body: actionTemplate,
    },
  ];

  const nestedNestedColumnsData = [
    // {
    //   field: "courtId",
    //   header: "Court ID",
    // },
    {
      field: "courtName",
      header: "Court Name",
    },
    {
      field: "slots.amountPerSlot",
      header: "Amount",
    },
    {
      field: "slots.timePerSlot",
      header: "Time",
    },
    {
      field: "slots.totalSlots",
      header: "Total Slot",
    },
    {
      field: "slots.startTime",
      header: " Start Time",
    },
    {
      header: "Image",
      body: imageNestedNestedTemplete,
    },
  ];

  const nestedRowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable value={data.courts}>
          {nestedNestedColumnsData.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
            />
          ))}
        </DataTable>
      </div>
    );
  };

  const nestedColumnsData = [
    {
      field: "sportId",
      header: "Sport ID",
    },
    {
      field: "sportName",
      header: "Sport Name",
    },
    {
      header: "Image",
      body: imageNestedTemplate,
    },
  ];

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable
          value={data.availableSports}
          expandedRows={expandedNestedRows}
          onRowToggle={(e) => setExpandedNestedRows(e.data)}
          rowExpansionTemplate={nestedRowExpansionTemplate}
        >
          <Column expander style={{ width: "3em" }} />
          {nestedColumnsData.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
            />
          ))}
        </DataTable>
      </div>
    );
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    setRows(event.rows);
  };

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
  };

  return (
    <>
      <ConfirmDialog
        visible={showConfirmDialog}
        onHide={() => setShowConfirmDialog(false)}
        message={
          isApproveAction ? (
            "Are you sure you want to approve this item?"
          ) : (
            <div>
              <p className="text-center">
                Are you sure you want to reject this item?
                <br /> If yes, please enter the reason in the Remarks section.
              </p>
              <div>
                <label className="mb-2">
                  Remarks <span className="required error-indicator">*</span>
                </label>

                <InputTextarea
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  placeholder="Enter remarks"
                  rows={5}
                  cols={30}
                />
              </div>
            </div>
          )
        }
        header="Confirmation"
        icon="pi pi-question-circle"
        accept={isApproveAction ? handleApproveConfirm : handleRejectConfirm}
        reject={() => setShowConfirmDialog(false)}
        footer={
          <div className="flex dialog-footer">
            <Button
              label="Cancel"
              className="p-button secondary-button "
              onClick={() => setShowConfirmDialog(false)}
            />
            <Button
              label="Submit"
              className="p-button"
              onClick={() => {
                if (isApproveAction) {
                  handleApproveConfirm();
                  setShowConfirmDialog(false);
                } else {
                  if (!remarks.trim()) {
                    setRemarksError("Remarks are required.");
                  } else {
                    setRemarksError("");
                    handleRejectConfirm();
                    setShowConfirmDialog(false);
                  }
                }
              }}
              disabled={!isApproveAction && !remarks.trim()}
            />
          </div>
        }
      />

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>
      </div>

      <div className="mb-4 tab-buttons">
        <Button
          onClick={() => handleTabChange("Pending")}
          className={`p-button ${
            currentTab === "Pending" ? "p-button-text-bold active-tab" : ""
          }`}
        >
          Pending
        </Button>
        <Button
          onClick={() => handleTabChange("Approved")}
          className={`p-button ${
            currentTab === "Approved" ? "p-button-text-bold active-tab" : ""
          }`}
        >
          Approved
        </Button>
        <Button
          onClick={() => handleTabChange("Rejected")}
          className={`p-button ${
            currentTab === "Rejected" ? "p-button-text-bold active-tab" : ""
          }`}
        >
          Rejected
        </Button>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          scrollable
          scrollHeight="500px"
          emptyMessage="No record found."
          value={filteredList}
          className="custom-data-table"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey="sportsComplexId"
        >
          <Column expander style={{ width: "3em" }} />
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>

        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
}
