import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createSportsComplex,
  getCitiesList,
  getSportsComplexList,
} from "../../../../redux/zsports_slice/zsports_slice";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { useFormik, FieldArray, FormikProvider, Form } from "formik";
import deleteIcon from "../../../../../assets/sports_assests/deletewhite.png";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";

const validationSchema = Yup.object({
  cityId: Yup.string().required("City is required"),
  complexName: Yup.string().required("Complex Name is required"),
  sportId: Yup.string().required("Sport is required"),
  image: Yup.string().required("Complex Image is required"),
  courts: Yup.array().of(
    Yup.object({
      courtName: Yup.string().required("Court Name is required"),
      courtImage: Yup.string().required("Court Image is required"),
      timePerSlot: Yup.number().required("Time Per Slot is required"),
      amountPerSlot: Yup.number().required("Amount Per Slot is required"),
      startTime: Yup.string().required("Starting Time is required"),
      totalSlots: Yup.number().required("Total Slots is required"),
    })
  ),
});

const EditAddForm = ({}) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const { citiesList, sportsComplexList, loading } = useSelector(
    (state) => state.sportsComplex
  );

  const { user } = useSelector((state) => state.loginUser);

  const [complexImageName, setComplexImageName] = useState("");
  const [courtImageNames, setCourtImageNames] = useState([]);

  useEffect(() => {
    dispatch(getCitiesList());
    dispatch(getSportsComplexList());
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      mobileNumber: "",
      cityId: "",
      complexName: "",
      location: "",
      sportId: "",
      image: "",
      courtImage: "",
      courts: [
        {
          courtName: "",
          courtImage: "",
          timePerSlot: "",
          amountPerSlot: "",
          startTime: "",
          totalSlots: "",
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const sportsComplexData = {
        mobileNumber: user?.user?.mobileNumber,
        complexName: values.complexName,
        cityId: values.cityId,
        address: values.address,
        image: values.image,
        availableSports: [
          {
            sportId: values.sportId,
            courts: values.courts.map((court) => ({
              courtName: court.courtName,
              courtImage: court.courtImage,
              slots: {
                timePerSlot: parseInt(court.timePerSlot),
                amountPerSlot: parseInt(court.amountPerSlot),
                startTime: formatTime(court.startTime),
                totalSlots: parseInt(court.totalSlots),
              },
            })),
          },
        ],
      };

      dispatch(createSportsComplex(sportsComplexData))
        .then(() => {
          showSuccessDialog();
          history.push("/schedule");
        })
        .catch(() => {});
    },
  });

  const handleFileUpload = (event, fieldName, index = null) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result.split(",")[1];
        formik.setFieldValue(fieldName, base64String);
        if (index !== null) {
          const newCourtImageNames = [...courtImageNames];
          newCourtImageNames[index] = file.name;
          setCourtImageNames(newCourtImageNames);
        } else {
          setComplexImageName(file.name);
        }

        fileInput.value = "";
      };

      reader.readAsDataURL(file);
    }
  };

  const formatTime = (time) => {
    if (!time) return "";
    let [hours, minutes] = time.split(":");
    hours = parseInt(hours, 10);
    hours = hours % 12 || 12;
    return `${hours.toString().padStart(2, "0")}:${minutes}:00`;
  };

  const handleCancel = (event) => {
    event.preventDefault();
    history.push({
      pathname: "/schedule",
    });
  };

  const [showDialog, setShowDialog] = useState(false);

  const showSuccessDialog = () => {
    setShowDialog(true);
  };

  const hideSuccessDialog = () => {
    setShowDialog(false);
  };

  const dialogContent = (
    <div>
      <p>Successfully added sports complex!</p>
    </div>
  );

  return (
    <div className="main-form">
      <FormikProvider value={formik}>
        <Dialog header="Success" visible={false}>
          <p>Success!</p>
        </Dialog>

        <Form>
          <div className="form-heading">
            <h5>Add New</h5>
          </div>

          <div className="p-fluid formgrid grid">
            <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
              <label htmlFor="cityId">
                City <span className="error-indicator required">*</span>
              </label>
              <Dropdown
                id="cityId"
                name="cityId"
                options={citiesList.map((city) => ({
                  label: city.cityName,
                  value: city.cityId,
                }))}
                placeholder="Select City"
                value={formik.values.cityId}
                onChange={(e) => formik.setFieldValue("cityId", e.value)}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.cityId && formik.touched.cityId
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.cityId && formik.touched.cityId && (
                <small className="p-error">{formik.errors.cityId}</small>
              )}
            </div>
            <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
              <label htmlFor="complexName">
                Complex Name <span className="error-indicator required">*</span>
              </label>
              <InputText
                id="complexName"
                name="complexName"
                placeholder="Enter complex name"
                value={formik.values.complexName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.complexName && formik.touched.complexName
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.complexName && formik.touched.complexName && (
                <small className="p-error">{formik.errors.complexName}</small>
              )}
            </div>
            <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
              <label htmlFor="sportId">
                Sports <span className="error-indicator required">*</span>
              </label>
              <Dropdown
                id="sportId"
                name="sportId"
                options={sportsComplexList.map((sport) => ({
                  label: sport.sportName,
                  value: sport.id,
                }))}
                placeholder="Select Sport"
                value={formik.values.sportId}
                onChange={(e) => formik.setFieldValue("sportId", e.value)}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.sportId && formik.touched.sportId
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.sportId && formik.touched.sportId && (
                <small className="p-error">{formik.errors.sportId}</small>
              )}
            </div>
            <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
              <label htmlFor="complexName">Location</label>
              <InputText
                id="address"
                name="address"
                placeholder="Enter location"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                className={
                  formik.errors.address && formik.touched.address
                    ? "p-invalid"
                    : ""
                }
              />
              {formik.errors.address && formik.touched.address && (
                <small className="p-error">{formik.errors.address}</small>
              )}
            </div>
          </div>

          <div className="p-fluid formgrid grid colored-row">
            <div className="field md:col-4 responsive-col  ">
              <label htmlFor="complexImage">Complex Image</label>
              <div className="file-input">
                <input
                  type="text"
                  name="image"
                  value={complexImageName || "Choose complex image"}
                  readOnly
                />
                <label
                  htmlFor="complexImageUpload"
                  className="choose-file-button"
                >
                  Choose File
                </label>
                <input
                  name="image"
                  type="file"
                  id="complexImageUpload"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, "image")}
                  className="choose-image-none"
                />
              </div>
              {formik.errors.image && formik.touched.image && (
                <small className="p-error">{formik.errors.image}</small>
              )}
            </div>
          </div>

          <FieldArray name="courts">
            {({ insert, remove, push }) => (
              <>
                {formik.values.courts.map((court, index) => (
                  <>
                    <div className="map-sports">
                      <div key={index} className="map-sport-container">
                        <div className="form-heading">
                          <h5>Add New Court Details</h5>
                        </div>
                        <div className="p-fluid formgrid grid">
                          {/* <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col">
                            <label htmlFor="sportId">
                              Sports{" "}
                              <span className="error-indicator required">
                                *
                              </span>
                            </label>
                            <Dropdown
                              id="sportId"
                              name={`courts.${index}.sportId`}
                              options={sportsComplexList.map((sport) => ({
                                label: sport.sportName,
                                value: sport.id,
                              }))}
                              placeholder="Select sport"
                              value={formik.values.sportId}
                              onChange={(e) =>
                                formik.setFieldValue("sportId", e.value)
                              }
                              onBlur={formik.handleBlur}
                              className={
                                formik.errors.sportId && formik.touched.sportId
                                  ? "p-invalid"
                                  : ""
                              }
                            />
                            {formik.errors.sportId &&
                              formik.touched.sportId && (
                                <small className="p-error">
                                  {formik.errors.sportId}
                                </small>
                              )}
                          </div> */}
                          <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col">
                            <label htmlFor={`courts.${index}.courtName`}>
                              Court Name{" "}
                              <span className="error-indicator required">
                                *
                              </span>
                            </label>
                            <InputText
                              placeholder="Enter court name"
                              id={`courts.${index}.courtName`}
                              name={`courts.${index}.courtName`}
                              value={court.courtName}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.errors.courts?.[index]?.courtName &&
                                formik.touched.courts?.[index]?.courtName
                                  ? "p-invalid"
                                  : ""
                              }
                            />
                            {formik.errors.courts?.[index]?.courtName &&
                              formik.touched.courts?.[index]?.courtName && (
                                <small className="p-error">
                                  {formik.errors.courts[index].courtName}
                                </small>
                              )}
                          </div>
                          <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col">
                            <label htmlFor={`courts.${index}.timePerSlot`}>
                              Slot Timing Per Court (min)
                              <span className="error-indicator required">
                                *
                              </span>
                            </label>
                            <InputText
                              placeholder="Enter slot timing per court"
                              id={`courts.${index}.timePerSlot`}
                              name={`courts.${index}.timePerSlot`}
                              value={court.timePerSlot}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.errors.courts?.[index]?.timePerSlot &&
                                formik.touched.courts?.[index]?.timePerSlot
                                  ? "p-invalid"
                                  : ""
                              }
                            />
                            {formik.errors.courts?.[index]?.timePerSlot &&
                              formik.touched.courts?.[index]?.timePerSlot && (
                                <small className="p-error">
                                  {formik.errors.courts[index].timePerSlot}
                                </small>
                              )}
                          </div>
                          <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col">
                            <label htmlFor={`courts.${index}.amountPerSlot`}>
                              Amount Per Slot (PKR)
                              <span className="error-indicator required">
                                *
                              </span>
                            </label>
                            <InputText
                              placeholder="Enter amount per slot"
                              id={`courts.${index}.amountPerSlot`}
                              name={`courts.${index}.amountPerSlot`}
                              value={court.amountPerSlot}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.errors.courts?.[index]?.amountPerSlot &&
                                formik.touched.courts?.[index]?.amountPerSlot
                                  ? "p-invalid"
                                  : ""
                              }
                            />
                            {formik.errors.courts?.[index]?.amountPerSlot &&
                              formik.touched.courts?.[index]?.amountPerSlot && (
                                <small className="p-error">
                                  {formik.errors.courts[index].amountPerSlot}
                                </small>
                              )}
                          </div>
                        </div>
                        <div className="p-fluid formgrid grid colored-row">
                          <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col">
                            <label htmlFor={`courts.${index}.courtImage`}>
                              Court Image
                            </label>
                            <div className="file-input">
                              <input
                                type="text"
                                name="courtImage"
                                value={
                                  courtImageNames[index] || "Choose Court Image"
                                }
                                readOnly
                              />
                              <label
                                htmlFor={`courtImageUpload${index}`}
                                className="choose-file-button"
                              >
                                Choose File
                              </label>
                              <input
                                name="courtImage"
                                type="file"
                                id={`courtImageUpload${index}`}
                                accept="image/*"
                                onChange={(e) =>
                                  handleFileUpload(
                                    e,
                                    `courts.${index}.courtImage`,
                                    index
                                  )
                                }
                                className="choose-image-none"
                              />
                            </div>
                            {formik.errors.courts?.[index]?.courtImage &&
                              formik.touched.courts?.[index]?.courtImage && (
                                <small className="p-error">
                                  {formik.errors.courts[index].courtImage}
                                </small>
                              )}
                          </div>
                        </div>
                        <div className="p-fluid formgrid grid ">
                          <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col">
                            <label htmlFor={`courts.${index}.startTime`}>
                              Start Time{" "}
                              <span className="error-indicator required">
                                *
                              </span>
                            </label>
                            <InputText
                              placeholder="Enter start time"
                              type="time"
                              id={`courts.${index}.startTime`}
                              name={`courts.${index}.startTime`}
                              value={court.startTime}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.errors.courts?.[index]?.startTime &&
                                formik.touched.courts?.[index]?.startTime
                                  ? "p-invalid"
                                  : ""
                              }
                            />
                            {formik.errors.courts?.[index]?.startTime &&
                              formik.touched.courts?.[index]?.startTime && (
                                <small className="p-error">
                                  {formik.errors.courts[index].startTime}
                                </small>
                              )}
                          </div>

                          <div className="field md:col-6 lg:col-6 xl:col-4 responsive-col">
                            <label htmlFor={`courts.${index}.totalSlots`}>
                              Total Slots{" "}
                              <span className="error-indicator required">
                                *
                              </span>
                            </label>
                            <InputText
                              placeholder="Enter total slots"
                              id={`courts.${index}.totalSlots`}
                              name={`courts.${index}.totalSlots`}
                              value={court.totalSlots}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={
                                formik.errors.courts?.[index]?.totalSlots &&
                                formik.touched.courts?.[index]?.totalSlots
                                  ? "p-invalid"
                                  : ""
                              }
                            />
                            {formik.errors.courts?.[index]?.totalSlots &&
                              formik.touched.courts?.[index]?.totalSlots && (
                                <small className="p-error">
                                  {formik.errors.courts[index].totalSlots}
                                </small>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="justify-content-center align-items-center ">
                        <Button
                          type="button"
                          className="p-button-danger px-3 remove-button"
                          onClick={() => remove(index)}
                          disabled={formik.values.courts.length === 1}
                        >
                          <img src={deleteIcon} alt="Remove" />
                        </Button>
                        <Button
                          type="button"
                          className="p-button-success mt-3 pt-4 pb-4 pr-5 pl-5 add-button"
                          label=""
                          icon="pi pi-plus"
                          onClick={() =>
                            push({
                              courtName: "",
                              courtImage: "",
                              timePerSlot: "",
                              amountPerSlot: "",
                              startTime: "",
                              totalSlots: "",
                            })
                          }
                        />
                      </div>
                    </div>
                  </>
                ))}
              </>
            )}
          </FieldArray>

          <div className="flex buttons">
            <Button label="Submit" className="p-button" type="submit" />
            <Button
              label="Cancel"
              className="p-button-secondary"
              onClick={handleCancel}
            />
          </div>
        </Form>
      </FormikProvider>
    </div>
  );
};

export default EditAddForm;
