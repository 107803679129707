import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSportComplexById } from "../../../../redux/zsports_slice/zsports_slice";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { Tag } from "primereact/tag";
import "../../../../../styles/customtable.scss";
import CustomImagePreview from "../../../components/custom_imagepreview";

const ViewDetails = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { id: sportsComplexId } = useParams();

  const sportsComplexReducer = useSelector(
    (state) => state.sportsComplex || {}
  );
  const { sportComplexByIdList, loading } = sportsComplexReducer;

  useEffect(() => {
    if (sportsComplexId) {
      dispatch(getSportComplexById(sportsComplexId));
    }
  }, [dispatch, sportsComplexId]);

  const {
    complexName,
    cityName,
    address,
    availableSports,
    isApproved,
    image,
    remarks,
  } = sportComplexByIdList || {};

  const getStatusTag = () => {
    if (isApproved === true) {
      return <Tag severity="success" value="Approved" />;
    } else if (isApproved === false) {
      return <Tag severity="danger" value="Rejected" />;
    } else {
      return <Tag severity="info" value="Pending" />;
    }
  };

  return (
    <div className="main-form">
      <form>
        <div className="form-heading">
          <h5>View Details</h5>
        </div>
        <div className="detailed-view">
          <div className="viewdetails">
            <div className="inner">
              <h6> Status:</h6>
              <p>{getStatusTag()}</p>
            </div>
            <div className="inner">
              <h6>Complex Name:</h6>
              <p>{complexName}</p>
            </div>
            <div className="inner">
              <h6>City Name:</h6>
              <p>{cityName}</p>
            </div>
            <div className="inner">
              <h6>Remarks:</h6>
              <p>{remarks ? remarks : "N/A"}</p>
            </div>
            <div className="inner">
              <h6>Address:</h6>
              <p>{address ? address : "N/A"}</p>
            </div>
            <div className="inner">
              <h6>Complex Image:</h6>
              <CustomImagePreview src={image} alt="Image" />
            </div>
            <div className="inner">
              <h6>Sport Name:</h6>
              <p>
                {availableSports && availableSports.length > 0
                  ? availableSports[0].sportName
                  : "N/A"}
              </p>
            </div>
          </div>

          {/* Render available sports and their courts */}
          {availableSports && availableSports.length > 0 && (
            <div className="courts-map">
              {availableSports.map((sport) => (
                <div key={sport.sportId}>
                  {/* <label>Sport Name</label>
                <div className="p-fluid formgrid grid">
                  <h6 className="field md:col-12 lg:col-12 xl:col-12 responsive-col">
                    {sport.sportName}
                  </h6>
                </div> */}
                  {sport.courts && sport.courts.length > 0 && (
                    <div className="flex">
                      {sport.courts.map((court) => (
                        <div key={court.courtId} className="mb-5">
                          <div className="courts-view">
                            <h5>Courts:</h5>
                            <div className="inner">
                              <h6>Court Name:</h6>
                              <p>{court.courtName}</p>
                            </div>

                            <div className="inner">
                              <h6>Court Image:</h6>

                              <CustomImagePreview
                                src={court.courtImage}
                                // className="img-view"
                                alt="Image"
                              />
                            </div>
                          </div>

                          {/* Render slots */}
                          <div className="slot-details">
                            <h5 className="mt-3">Slots Details:</h5>
                            <div className="inner">
                              <h6>Start Time:</h6>
                              <p>{court.slots.startTime}</p>
                            </div>
                            <div className="inner">
                              <h6>Amount Per Slot:</h6>
                              <p> {court.slots.amountPerSlot} (PKR) </p>
                            </div>

                            <div className="inner">
                              <h6>Time Per Slot: </h6>
                              <p>{court.slots.timePerSlot} (min)</p>
                            </div>
                            <div className="inner">
                              <h6>Total Slots: </h6>
                              <p>{court.slots.totalSlots}</p>
                            </div>
                          </div>
                          {/* Render slots data */}
                          {court.slotsData && court.slotsData.length > 0 && (
                            <div className="mt-4">
                              {/* <h6>Slots</h6> */}
                              <div className="slots-container">
                                {court.slotsData.map((slot) => (
                                  <div className="cardslote" key={slot.slotId}>
                                    <p>
                                      <b>{slot.slotName}</b>
                                    </p>
                                    <p>{slot.slotTime}</p>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="flex buttons">
          <Button
            label="Cancel"
            className="p-button-secondary"
            onClick={() => history.push("/schedule")}
          />
        </div>
      </form>
    </div>
  );
};

export default ViewDetails;
