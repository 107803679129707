import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { getSportsComplexWithCities } from "../../../../redux/zsports_slice/zsports_slice";
import { useDispatch, useSelector } from "react-redux";
import { Tag } from "primereact/tag";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom";
import Loader from "../../../components/loader";
import CustomImagePreview from "../../../components/custom_imagepreview";

export default function ScheduleScreen() {
  const sportsComplexReducer = useSelector(
    (state) => state.sportsComplex || {}
  );
  const { complexWithCitiesList, loading } = sportsComplexReducer;
  const { user } = useSelector((state) => state.loginUser);

  const [filteredList, setFilteredList] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [expandedRows, setExpandedRows] = useState(null);
  const [expandedNestedRows, setExpandedNestedRows] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (user && user.user && user.user.mobileNumber) {
      const userMobileNumber = user.user.mobileNumber;
      dispatch(getSportsComplexWithCities(userMobileNumber));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (complexWithCitiesList) {
      setFilteredList(complexWithCitiesList);
    }
  }, [complexWithCitiesList]);

  const handleEditAddFormNavigation = (rowData) => {
    history.push({
      pathname: "/schedule/edit-add-form",
      state: { formData: rowData },
    });
  };

  const handleViewNavigation = (rowData) => {
    history.push(`/schedule/view-details/${rowData.sportsComplexId}`);
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="flex">
        <Button
          className="secondary-btn"
          onClick={() => handleViewNavigation(rowData)}
          aria-label="Edit"
        >
          Details
        </Button>
      </div>
    );
  };

  const imageTemplate = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={rowData.image} />
      </div>
    );
  };

  const imageNestedTemplate = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={rowData.sportImage} />
      </div>
    );
  };
  const imageNestedNestedTemplete = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={rowData.courtImage} />
      </div>
    );
  };

  const columnsData = [
    {
      field: "sportsComplexId",
      header: "Complex ID",
    },
    {
      field: "complexName",
      header: "Complex Name",
    },
    {
      field: "cityName",
      header: "City Name",
    },
    {
      header: "Status",
      body: (rowData) => {
        if (rowData.isApproved === true) {
          return <Tag severity="success" value="Approved" />;
        } else if (rowData.isApproved === false) {
          return <Tag severity="danger" value="Rejected" />;
        } else {
          return <Tag severity="contrast" value="Pending" />;
        }
      },
    },
    {
      field: "remarks",
      header: "Remarks",
      body: (rowData) => {
        return rowData.remarks ? rowData.remarks : "N/A";
      },
    },
    {
      header: "Image",
      body: imageTemplate,
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const nestedNestedColumnsData = [
    {
      field: "courtName",
      header: "Court Name",
    },
    {
      field: "slots.amountPerSlot",
      header: "Amount",
      body: (rowData) => {
        return `${rowData.slots.amountPerSlot.toString().toUpperCase()} (PKR)`;
      },
    },
    {
      field: "slots.timePerSlot",
      header: "Time",
      body: (rowData) => {
        return (
          <span style={{ textTransform: "lowercase" }}>
            {`${rowData.slots.timePerSlot} (min)`}
          </span>
        );
      },
    },
    {
      field: "slots.totalSlots",
      header: "Total Slot",
    },
    {
      field: "slots.startTime",
      header: " Start Time",
    },
    {
      header: "Image",
      body: imageNestedNestedTemplete,
    },
  ];

  const nestedRowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable value={data.courts}>
          {nestedNestedColumnsData.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
            />
          ))}
        </DataTable>
      </div>
    );
  };

  const nestedColumnsData = [
    {
      field: "sportId",
      header: "Sport ID",
    },
    {
      field: "sportName",
      header: "Sport Name",
    },
    {
      header: "Image",
      body: imageNestedTemplate,
    },
    // {
    //   field: "image",
    //   header: "Sport Image",
    //   body: (rowData) => (
    //     <ul>
    //       {rowData.courts.map((court, index) => (
    //         <li key={index}>{court.courtName}</li>
    //       ))}
    //     </ul>
    //   ),
    // },
  ];

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable
          value={data.availableSports}
          expandedRows={expandedNestedRows}
          onRowToggle={(e) => setExpandedNestedRows(e.data)}
          rowExpansionTemplate={nestedRowExpansionTemplate}
        >
          <Column expander style={{ width: "3em" }} />
          {nestedColumnsData.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
            />
          ))}
        </DataTable>
      </div>
    );
  };

  return (
    <>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              // onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10 justify-content-end filter-responsive">
          <Button
            label="Add Sports Complex"
            icon="pi pi-plus"
            className="p-button ml-3"
            onClick={() => handleEditAddFormNavigation()}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          scrollable
          scrollHeight="500px"
          emptyMessage="No record found."
          value={filteredList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          dataKey="sportsComplexId"
        >
          <Column expander style={{ width: "3em" }} />
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
}
