import React from "react";
import logo from "../../../assets/sports_assests/logo.png";

const Loader = ({  }) => {
  return (
    <>
     
      <div className="loader-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
    </>
  );
};

export default Loader;
