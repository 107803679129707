import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import CustomInputField from "../../components/custom_input_field";
import { loginUser } from "../../../redux/auth_slice/login_user_slice";
import logo from "../../../../assets/sports_assests/logo.png";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
export default function LoginScreen() {
  const { loading } = useSelector((state) => state.loginUser);
  const dispatch = useDispatch();
  const history = useHistory();

  const formik = useFormik({
    initialValues: {
      mobileNumber:  "",
      pin: "",
    },
    validationSchema: Yup.object().shape({
      pin: Yup.string()
        .required("Password is required.")
,
        mobileNumber: Yup.string()
        .required("Number is required.").max(11, ' Allow 11 characters'),
    }),
    onSubmit: async (data) => {
      dispatch(loginUser(data)).then(res=>{
      
      if(res?.payload?.token){
        toast.success('Successfully Login');
       return history.push('/');
     
      }
      
      else{
        toast.error('Invalid credentials');
      }
     
      history.push('/login'); 
      })
    },
  });

  return (
    <>
      <div className="auth_main">
        <div className="auth_login_box">
          <div className="auth-logo">
            <img src={logo} />
            <span>Z-Sports</span>
          </div>
          <h2 className="auth-welcome mt-3 text-align-center">
            Log In to Continue...
          </h2>

          <form
            className=" p-fluid justify-content-center align-items-left mt-5"
            onSubmit={formik.handleSubmit}
          >
            <CustomInputField
              label="Mobile Number"
              iden="mobileNumber"
              formik={formik}
              placeHolder="Enter mobile number"
              type="text"
              maxLength={11}
              onKeyUp={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                formik.setFieldValue("mobileNumber", e.target.value);
              }}
            />

            <CustomInputField
              label="Pin"
              iden="pin"
              formik={formik}
              placeHolder="Enter pin"
              type="password"
              feedback={false}
              maxLength={4}
              onKeyUp={(e) => {
                e.target.value = e.target.value.replace(/[^0-9]/g, "");
                formik.setFieldValue("pin", e.target.value);
              }}
            />

            <Button
              type="submit"
              className="customButton"
              label="Log In"
              loading={loading}
            />
          </form>
        </div>
      </div>
    </>
  );
}
